.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 250px;
    box-sizing: border-box;
}

a{
    text-decoration: none;
    color: rgb(0, 255, 234);
    font-size: small;
}

.loader .face{
    position: absolute;
    border: 2px solid #121212;
}



.loader .face.face1{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: #090909; */
    box-shadow: 0 0 10px rgba(0,0,0,1);
    border-radius: 50%;
    border-left: 2px solid white;
    border-top: 2px solid white;
    animation: animate 3s linear infinite;
}

.loader .face.face2{
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    background: rgba(0, 255, 234, 0.1);
    box-shadow: 0 0 10px rgba(0,0,0,1);
    border-radius: 50%;
    border-left: 2px solid white;
    border-top: 2px solid white;
    animation: animate 3s reverse infinite;
} 

.face.face3{
    position: absolute;
    top: 40%;
    right: 19%;
    z-index: 1;
    border: none;
}

.loader .face .circle {
    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    width: 50%;
    height: 2px;
    transform-origin: left;
}

.loader .face.face1 .circle {
    transform: rotate(-45deg);
}

.loader .face.face2 .circle {
    transform: rotate(130deg);
}

.loader .face .circle:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    top: -4px;
    right: -6px;
}

.loader .face.face1 .circle:before {
    background: white;
    box-shadow: 0 0 20px rgb(0, 255, 234),
                0 0 40px rgb(0, 255, 234),
                0 0 60px rgb(0, 255, 234),
                0 0 80px rgb(0, 255, 234),
                0 0 100px rgb(0, 255, 234),
                0 0 0 5px rgba(255, 255, 0, .1);
}

.loader .face.face2 .circle:before {
    background: white;
    box-shadow: 0 0 20px rgb(0, 255, 234),
                0 0 40px rgb(0, 255, 234),
                0 0 60px rgb(0, 255, 234),
                0 0 80px rgb(0, 255, 234),
                0 0 100px rgb(0, 255, 234),
                0 0 0 5px rgba(3, 169, 244, .1);
}

@keyframes animate
{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}